import React from 'react';
import './PrettyButton.css';
import { playButtonClickSound } from '../utilities/misc';

interface PrettyButtonProps {
  text: string;
  onClick: () => void;
  buttonColor?: string;
  disabled?: boolean;
  shareButton?: boolean;
  submitClueButton?: boolean;
  passTurnButton?: boolean;
}


const PrettyButton: React.FC<PrettyButtonProps> = ({ text, onClick, buttonColor, disabled, shareButton, submitClueButton, passTurnButton }) => {
  
  const handleClick = () => {
    playButtonClickSound();
    onClick();
  }

  return (
    <button className=
    {`pretty-button 
    ${disabled ? "disabled" : 
      shareButton ? "shareButton": 
      submitClueButton ? "submitClueButton": 
      passTurnButton ? "passTurnButton" : ""
    }`} 
      style={{backgroundColor: buttonColor}} onClick={handleClick}
      disabled={disabled}>
      {text}
    </button>
  );
};

export default PrettyButton;
