import React, { useState, useEffect } from 'react';
import './GameBoard.css';
import { GetOrSetPlayerId, playTileClickSound, saveLastGameCode, playBadTileClickSound, playButtonClickSound, playExplosionSound, 
  playGoodTileClickSound, playNewMessageSound, playNeutralTileClickSound, playWinningMusic, playLosingMusic } from '../utilities/misc';
import Header from '../components/Header';
import { Card, CardType, Player, Role, Clue, CurrentTurn, Team, Chat, Message } from '../types';
import { useWebSocket } from '../context/WebSocketContext';
import ClueInput from '../components/ClueInputTextBox';
import ClueNumberDropdown from '../components/ClueNumberDropdown';
import PrettyButton from '../components/PrettyButton';
import PrettyChatbox from '../components/PrettyChatbox';
import { useNavigate, useParams } from 'react-router-dom';


const GameBoard: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string }>();
  const [board, setBoard] = useState<Card[]>([]);
  const [redRemaining, setRedRemaining] = useState<number>(10);
  const [blueRemaining, setBlueRemaining] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPlayer, setCurrentPlayer] = useState<Player | undefined>();
  const { socket } = useWebSocket();
  const [isLocalChange, setIsLocalChange] = useState(false);
  const [cardIndexClicked, setCardIndexClicked] = useState<number>();
  const [registeredPlayers, setRegisteredPlayers] = useState<Player[]>();
  const [teammate, setTeammate] = useState<Player>();
  const [myTurn, setMyTurn] = useState<boolean>();
  const [currentTurn, setCurrentTurn] = useState<CurrentTurn>();
  const [currentClue, setCurrentClue] = useState<Clue>();
  const [localClueText, setLocalClueText] = useState<string>("");
  const [localClueMaxCount, setLocalClueMaxCount] = useState<number>(1);
  const [localClueReady, setLocalClueReady] = useState<boolean>(false);
  const [flashRed, setFlashRed] = useState<boolean>(false);
  const [turnPassed, setTurnPassed] = useState<boolean>(false);
  const [winner, setWinner] = useState<Team>();
  const [assassinClicked, setAssassinClicked] = useState<boolean>(false);
  const [newGameClicked, setNewGameClicked] = useState<boolean>(false);
  const [typedNumber, setTypedNumber] = useState<number>();
  const [connectionMessageText, setConnectionMessageText] = useState<string>("Loading...");
  const [guestViewer, setGuestViewer] = useState(false);
  const [chat, setChat] = useState<Chat>();
  const [numberOverridden, setNumberOverridden] = useState(false);
  const [endingMusicPlayed, setEndingMusicPlayed] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {

    const fetchBoard = () => {
      // console.log("Socket in main component:", socket);
      if (socket && socket.readyState === WebSocket.OPEN) {
        // console.log('Sending get_board request'); 
        if (gameCode)
        {
          saveLastGameCode(gameCode)
        }

        socket.send(
          JSON.stringify({
            type: 'get_board',
            gameCode,
            playerId: GetOrSetPlayerId()
          })
        );
        // console.log("sent")
      } else {
      //console.log('WebSocket not open yet'); 
        // console.log("WebSocket not open, fetchBoard skipped");
      }
    };

      // Function to periodically fetch the board
    const startFetchBoardInterval = () => {
      // console.log("startfetchboardinterval")
      return setInterval(() => {
        fetchBoard();
      }, 10000); 
    };

    if (socket && gameCode) {
      if (socket.readyState === WebSocket.OPEN) {
      //console.log('WebSocket is ready'); 
        fetchBoard();
      } else {
      //console.log('Waiting for WebSocket to open'); 
        socket.onopen = () => {
        //console.log('WebSocket opened'); 
          fetchBoard();
        };
      }

      
      
      // Listen for WebSocket messages
      const handleMessage = (event: MessageEvent) => {
      //console.log('Message received:', event.data); 
        const message = JSON.parse(event.data);
      //console.log("Gameboard message.type =", message.type);
      //console.log(message);
        if (message.type === 'get_board') {
          // console.log('get_board received')
          // console.log(message)
          setBoard(message.gameBoard);
          const registeredPlayers = message.registeredPlayers as Player[];

          const registeredPlayerIDs = registeredPlayers.map(r => r.id);


          if (!registeredPlayerIDs.some(x => x === GetOrSetPlayerId()))
          {
            // alert("Sorry, this game has already started.");
            // navigate(`/`);
          }

          const curPlayer = registeredPlayers.find(r => r.id === GetOrSetPlayerId());
          const receivedTurn = message.currentTurn as CurrentTurn;
          // console.log("get_board_receivedturn", receivedTurn)
          const receivedClue = message.currentClue as Clue;
          const winner = message.winner as Team;
          const assassin = message.assassin as boolean;

          setChat(message.chat);
          setWinner(winner);
          setAssassinClicked(assassin);
          setCurrentTurn(receivedTurn);
          setCurrentClue(receivedClue);
          setCurrentPlayer(curPlayer);
          setRegisteredPlayers(registeredPlayers);
          setLoading(false);
          setNewGameClicked(false);
        }
        else if (message.type === 'updated_board'){
          // console.log("updated board received")
          // console.log(message)
          const winner = message.winner as Team;
          const assassin = message.assassin as boolean;

          const receivedTurn = message.currentTurn as CurrentTurn;
          const receivedClue = message.currentClue as Clue;

          const receivedIndexClicked = message.indexClicked;
          
          const cardForSound = message.gameBoard[receivedIndexClicked]

          setEndingMusicPlayed(false);


          playTileSound(cardForSound, receivedTurn);

          setWinner(winner);
          setAssassinClicked(assassin);
          setIsLocalChange(false);
          setBoard(message.gameBoard);
          setCurrentClue(receivedClue);
          setCurrentTurn(receivedTurn);


        }

        else if (message.type === "clue_given"){
          const receivedTurn = message.currentTurn as CurrentTurn;
          const receivedClue = message.currentClue as Clue;
          
          setCurrentTurn(receivedTurn);
          setCurrentClue(receivedClue);

        }
        else if (message.type === "error"){
          setConnectionMessageText(message.message);
        }
      };

      socket.onclose = () => {
        setConnectionMessageText("Server Error")
      }

      const intervalFetch = startFetchBoardInterval();

      // Register the listener
      socket.addEventListener('message', handleMessage);

    //console.log('WebSocket listener added'); 

      return () => {
      //console.log('Cleaning up WebSocket listener'); 
        socket.removeEventListener('message', handleMessage); // Clean up listener on unmount
        clearInterval(intervalFetch)
      };
    }
  }, [socket]);

  useEffect (() => {
    const updateBoard = () => {
      if (socket && socket.readyState === WebSocket.OPEN && (isLocalChange || turnPassed)) {
      //console.log('Sending update_board request'); 
        socket.send(
          JSON.stringify({
            type: 'update_board',
            // newBoard: board,
            passTurn: turnPassed,
            indexClicked: cardIndexClicked,
            gameCode
          })
        );
      } else {
      }
    };

    if (socket && gameCode) {
      if (socket.readyState === WebSocket.OPEN) {
        updateBoard();
      } else {
        socket.onopen = () => {
          updateBoard();
        };
      }
    }
    setTurnPassed(false);

}, [board, isLocalChange, turnPassed])

useEffect(() => {
  const redLeft = board.filter(x => x.revealColor === CardType.Red && !x.revealed).length;
  const blueLeft = board.filter(x => x.revealColor === CardType.Blue && !x.revealed).length;

  setRedRemaining(redLeft || 0);
  setBlueRemaining(blueLeft || 0);


}, [board])

  useEffect (() => {
    const giveClue = () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
      //console.log('Sending give_clue request'); 
        socket.send(
          JSON.stringify({
            type: 'give_clue',
            gameCode,
            word: localClueText,
            maxClicks: localClueMaxCount
          })
        );
      } 
      else {
      //console.log('WebSocket not open yet'); 
      }
    };

    if (socket && gameCode && localClueReady) {
      if (socket.readyState === WebSocket.OPEN) {
        giveClue();
      } else {
        socket.onopen = () => {
          giveClue();
        };
      }

      setLocalClueMaxCount(1);
      setLocalClueReady(false);
      setLocalClueText("");

  }}, [localClueReady])

  useEffect (() => {
    
    const isItMyTurn = currentPlayer?.role === currentTurn?.role && currentPlayer?.team === currentTurn?.team;

    setMyTurn(isItMyTurn);

    if (!currentPlayer?.role)
    {
      setGuestViewer(true)
    }
    else
    {
      setGuestViewer(false);
    }

  }, [currentPlayer, currentTurn])

  useEffect (() => {
    
  //console.log("my turn changed")

  }, [myTurn])

  useEffect (() => {
    
    if (registeredPlayers && currentPlayer)
    {
      const myTeammate = registeredPlayers?.find(rp => rp.team === currentPlayer?.team && rp.role !== currentPlayer?.role)
      setTeammate(myTeammate);
    }
  

  }, [registeredPlayers])


  // Use useEffect to reset flashRed back to false after a timeout
  useEffect(() => {
    if (flashRed) {
      const timer = setTimeout(() => {
        setFlashRed(false); // Reset the state back to false
      }, 2000); // 2000ms = 2 seconds

      // Clean up the timer when the effect is done or component is unmounted
      return () => clearTimeout(timer);
    }
  }, [flashRed]); // This effect runs whenever `flashRed` changes

  useEffect(() => {
    if (typedNumber && localClueMaxCount === 1)
    {
      setNumberOverridden(true)
      setLocalClueMaxCount(typedNumber)
    }
  }, [typedNumber])
  
  const handleCardClick = (index: number) => {
    if (!myTurn || currentTurn?.role === Role.Spymaster)
    {
      return;
    }
    const updatedBoard = [...board];
    const alreadyRevealed = updatedBoard[index].revealed;

    if (alreadyRevealed)
    {
      return;
    }

    // playTileClickSound();

    updatedBoard[index].revealed = true;

    // playTileSound(updatedBoard[index]);

    setIsLocalChange(true);
    setCardIndexClicked(index);
    setBoard(updatedBoard);
  };

  const onClueTextChange = (clueText: string) => {
    setLocalClueText(clueText);
  }

  const onClueMaxNumberChange = (maxClick: number) => {
    setLocalClueMaxCount(maxClick)
  }

  const onSubmitClueClicked = () => {
    if (localClueMaxCount !== undefined && localClueText.length > 1)
    {
      setLocalClueReady(true);
    }
    else
    {
      setFlashRed(true);
    }
  }

  const onPassTurnClicked = () => {
    if (myTurn)
    {
    //console.log("pass clicked")
      setTurnPassed(true);
    }
  }

  const onNewGameClicked = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      setNewGameClicked(true);
    //console.log('Sending update_board request'); 
      socket.send(
        JSON.stringify({
          type: 'new_game',
          gameCode,
          playerId: currentPlayer?.id
        })
      );
    } else {
    //console.log('WebSocket not open yet'); 
    }
    // alert("new game time!")
  }

  const getFirstTeam = (): string => {
    const redCount = board.filter(card => card.revealColor === CardType.Red).length;
    return redCount === 9 ? 'redFirst' : 'blueFirst';
  };

  const getCardKeyIcon = (card: Card): string => {
    switch (card.revealColor) {
      case CardType.Red:
        return '◆';
      case CardType.Blue:
        return '●';
      case CardType.Assassin:
        return '✖';
      default:
        return '';
    }
  };

  const renderBoardKey = () => (
    <div className={`boardKey ${getFirstTeam()}`}>
      {board.map((card, index) => (
        <div key={index} className={`cardKey ${card.revealColor}`}>
          {getCardKeyIcon(card)}
        </div>
      ))}
    </div>
  );

  const getStatusText = () => {

    if (winner)
    {
      
      const winnerNames = registeredPlayers?.filter(r => r.team === winner).map(m => m.name).join(" and ")
      const winType = (assassinClicked ? `Assassin was revealed!` : "").toLocaleUpperCase()
      const allCaps = `${winnerNames} win!`.toLocaleUpperCase();

      if (!endingMusicPlayed) {
        setEndingMusicPlayed(true);
      
        if (currentPlayer?.team && currentPlayer.team === winner) {
          playWinningMusic();
        } else if (currentPlayer?.team) {
          playLosingMusic();
        }
      }

      return (
        <div className={`winner ${winner}`}>
          <div>{allCaps}</div>
          <div>{winType}</div>
          <br />
        </div>
      )
    }

    const waiting = currentTurn?.role === Role.Spymaster;

    const redTotal = board.filter(x => x.revealColor === CardType.Red).length;
    const blueTotal = board.filter(x => x.revealColor === CardType.Blue).length;
    

    const firstTeam = redTotal > blueTotal ? Team.Red : Team.Blue;
    const secondTeam = firstTeam === Team.Red ? Team.Blue : Team.Red;
    const firstTeamClass = `remainingCards ${firstTeam}`
    const secondTeamClass = `remainingCards ${secondTeam}`

    const firstTeamPretty = firstTeam.charAt(0).toUpperCase() + firstTeam.slice(1);
    const secondTeamPretty = secondTeam.charAt(0).toUpperCase() + secondTeam.slice(1);

    const firstTeamRemaining = firstTeam === Team.Red ? redRemaining : blueRemaining;
    const secondTeamRemaining = firstTeam === Team.Red ? blueRemaining : redRemaining;

    const firstTeamStart = firstTeam === Team.Red ? redTotal : blueTotal;
    const secondTeamStart = firstTeam === Team.Red ? blueTotal : redTotal;


    const firstTeamFound = firstTeamStart - firstTeamRemaining;
    const secondTeamFound = secondTeamStart - secondTeamRemaining;

    const firstTeamNames = registeredPlayers?.filter(x => x.team === firstTeam).map(x => x.name?.substring(0,20)).join(" and ")
    const secondTeamNames = registeredPlayers?.filter(x => x.team === secondTeam).map(x => x.name?.substring(0,20)).join(" and ")


    const remainingLine1 = `${firstTeamNames}: ${firstTeamFound}/${firstTeamStart}`
    const remainingLine2 = `${secondTeamNames}: ${secondTeamFound}/${secondTeamStart}`

    if (waiting)
    {
      const teamColor = currentTurn.team.charAt(0).toUpperCase() + currentTurn.team.slice(1).toLowerCase()
      return (
        <div >

          <span className={`${firstTeamClass}`}>{remainingLine1}</span>
          <span> | </span>
          <span className={`${secondTeamClass}`}>{remainingLine2}</span>

          <div className={`currentClue ${currentTurn?.team}`}>
            <div>{`Waiting for ${teamColor} Team clue...`}</div>
            <br />
          </div>
        </div>

      )
    }
    
    else
    {
      return (
        <div>
          <span className={`${firstTeamClass}`}>{remainingLine1}</span>
          <span> | </span>
          <span className={`${secondTeamClass}`}>{remainingLine2}</span>
          <div className={`currentClue ${currentTurn?.team}`}>
          <div>CLUE: {currentClue?.word}</div>
          <div>Guesses Remaining: {currentTurn?.clicksRemaining}</div>

          {/* one line */}
          {/* <div>
            <span>CLUE: </span><span className={`currentClue ${currentTurn?.team}`}>{currentClue?.word}</span>
            <span> | </span>
            <span>Guesses:</span> <span className={`currentClue ${currentTurn?.team}`}>{currentTurn?.clicksRemaining}</span>
          </div> */}
          
        </div>
        </div>
        
      )

    }
  }

  const onNumberValueChanged= (numString: string) => {
    if (numString)
    {
      // console.log("numstring:", numString)
      setTypedNumber(parseInt(numString) || 1);

    }
  }

  function playTileSound(selectedCard: Card, turnInfo: CurrentTurn) {

    if (!selectedCard?.revealColor)
    {
      playNeutralTileClickSound();
      return;
    }

    const currentTeam = turnInfo?.team;
    const derivedTeam = turnInfo?.teamSwitch ? (currentTeam === Team.Red ? Team.Blue : Team.Red) : currentTeam;
    // const currentTeam = currentPlayer?.team;
    // console.log(currentTeam)
  
    switch (selectedCard.revealColor) {
      case CardType.Assassin:
        playExplosionSound();
        break;
  
      case CardType.Bystander:
        playNeutralTileClickSound();
        break;
  
      case CardType.Red:
        derivedTeam === "red" ? playGoodTileClickSound() : playBadTileClickSound();
        break;
  
      case CardType.Blue:
        derivedTeam === "blue" ? playGoodTileClickSound() : playBadTileClickSound();
        break;
    }
  }
  
  const SpymasterControls: React.FC = () => {
    return (
      <>
        {winner ? (
          <>
            <NewGameButton />
            <div className="keyContainer">{renderBoardKey()}</div>
          </>
        ) : (
          <>
            <div className="keyContainer">{renderBoardKey()}</div>
            <div className='clueContainer'>
              <ClueInput value={localClueText} onClueTextChanged={onClueTextChange} flashRed={flashRed} disabled={!myTurn} onNumberTypedChanged={onNumberValueChanged}/>
              <ClueNumberDropdown value={localClueMaxCount} onValueChange={onClueMaxNumberChange} disabled={!myTurn} specialChange={numberOverridden}/>
            </div>
            <PrettyButton onClick={onSubmitClueClicked} text='Submit Clue' submitClueButton={true} disabled={!myTurn} />
          </>
        )}
      </>
    );
  };
  
  const GuesserControls: React.FC = () => {
    return (
      <>
        {winner ? (
          <div className='postGameGuesser'>
            <NewGameButton />
            {renderBoardKey()}
          </div>
        ) : (
          <PrettyButton onClick={onPassTurnClicked} text='Pass Turn' passTurnButton={true} disabled={!myTurn} />
        )}
      </>
    );
  };

  const PlayerInput: React.FC = () => (
    <div className='spymasterData'>
      {currentPlayer?.role === Role.Spymaster ? 
      <SpymasterControls /> : !guestViewer ?
      <GuesserControls /> : undefined}
    </div>
  );

  const NewGameButton: React.FC = () => {
    return (
      <div className='newGameButtonContainer'>
        <PrettyButton onClick={onNewGameClicked} text='New Game' />
      </div>
    )
  }

  const MyTeamInfo: React.FC = () => {

    const myRole = currentPlayer?.role;
    const otherRole = myRole === "spymaster" ? "guesser" : "spymaster";

    const opponents = registeredPlayers?.filter(x => x.team !== currentPlayer?.team)
    const otherSpymaster = opponents?.find(x => x.role === "spymaster")
    const otherGuesser = opponents?.find(x => x.role === "guesser")
    const otherTeam = opponents?.find(x => x.role)?.team


    if (guestViewer)
    {
      return <div className='myTeamInfo'>You are an observer.</div>
    }

    // setGuestViewer(false)
    return (
      <div className='teamInfoContainer'>
        <div className="myTeamInfo">
          {`You are the `}
          <span className={`myTeamInfo ${currentPlayer?.team}`}>
            {currentPlayer?.team} {myRole}
          </span>
          {`.  Your ${otherRole} is `}
          <span className={`myTeamInfo ${currentPlayer?.team}`}>
            {getTeammate()}
          </span>
          .
        </div>
        <div className="myTeamInfo">
          <span className={`myTeamInfo ${otherTeam}`}>
            {otherSpymaster?.name}
          </span>
          {` is the `}
          <span className={`myTeamInfo ${otherTeam}`}>
            {otherTeam} {otherSpymaster?.role}
          </span>
          {`.  Their ${otherGuesser?.role} is `}
          <span className={`myTeamInfo ${otherTeam}`}>
            {otherGuesser?.name}
          </span>
          .
        </div>
      </div>

      
    );
  };
  const PlaceholderText: React.FC = () => {
    const subClass = connectionMessageText.toLowerCase().includes("error") ? "error" : ""
    return <div className={`loadingBoard ${subClass}`}>{connectionMessageText}</div>;
  }

  const getTeammate = () => {
    return teammate ? teammate?.name : "Unknown player";
  }
  

  return (
    <div className="gamePage">
      <Header />
      {
        loading ? <PlaceholderText/> : 
        <>{getStatusText()}
                    {/* {GetOrSetPlayerId()} */}
          <div className="boardContainer">
            <div className="board">
              {board.map((card, index) => (
                <div
                  key={index}
                  className={`card ${myTurn && currentPlayer?.role === Role.Guesser ? "" : "disabled"} ${card.revealed ? card.revealColor : ''}`}
                  onClick={() => handleCardClick(index)}
                >
                  {card.word}
                </div>
              ))}
            </div>
          </div>
          {/* {isPlayerSpymaster() && ( */}
          <PlayerInput />
          <MyTeamInfo/>
          <PrettyChatbox gameCode={gameCode || ""} playerId={GetOrSetPlayerId() || ""} players={registeredPlayers || []} initialChat={chat || {messages: []}}></PrettyChatbox>
      </>
      }


    </div>
  );
};

export default GameBoard;


