import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PrettyButton from '../components/PrettyButton';
import './LandingPage.css';
import NumpadInput from '../components/NumpadPrompt';
import { useWebSocket } from '../context/WebSocketContext';
import Header from '../components/Header';
import { GetOrSetPlayerId, retrieveLastGameCode } from '../utilities/misc';
import { error } from 'console';

const LandingPage: React.FC = () => {
  // const connectedSuccessString = "Connected"
  const navigate = useNavigate();
  const [gameCode, setGameCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [hideRedPrompt, setHideRedPrompt] = useState<boolean>(true);
  const { socket } = useWebSocket();
  const [savedGameCode, setSavedGameCode] = useState<string>();
  const [retryAttempt, setRetryAttempt] = useState(0);
  // const [goodConnection, setGoodConnection] = useState(false);

  useEffect(() => {
    if (gameCode)
    {
      setHideRedPrompt(true);
      // setErrorMessage(undefined);
    }

    if (errorMessage)
    {
      setHideRedPrompt(false);
    }

  }, [gameCode,errorMessage]);

  // useEffect(() => {
  //   switch (errorMessage)
  //   {
  //     // case connectedSuccessString:
  //     //   setGoodConnection(true);
  //     //   break;
  //     default:
  //       setGoodConnection(false);
  //       break;
  //   }

  // }, [errorMessage]);
  
  useEffect(() => {

    const maybeCode = retrieveLastGameCode();
    setSavedGameCode(maybeCode);

  }, []);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event: any) => {
        const message = JSON.parse(event.data);
      console.log("Lobby message.type = ", message.type);
  
        if (message.type === 'joined_lobby') {
        //console.log("joining lobby :", gameCode);
        //console.log(message.message);
          const serverLobbyCode = message.gameCode
          navigate(`/lobby/${serverLobbyCode}`); 
        }
        else if (message.type === 'get_board')
        {
          const serverGameCode = message.gameCode;
          navigate(`/game/${serverGameCode}`);
        }
        else if (message.type === 'error')
        {
          setErrorMessage(message.message);
          // setHideRedPrompt(false);
        }

      };

      // socket.onclose = () => {
      //   setRetryAttempt(retryAttempt+1);
      //   // setErrorMessage(`Reconnecting...`)
      // }

      // socket.onopen =() => {
      //   setRetryAttempt(0);
      //   // setErrorMessage(connectedSuccessString);
      // }
  
      socket.addEventListener('message', handleMessage);
  
      return () => {
        socket.removeEventListener('message', handleMessage); // Cleanup listener on unmount
      };
    }
  }, [socket]);
  

  //create new game
  const handleCreateLobby = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({
        type: 'create_lobby',
        listName: 'default',
        wordCount: 25
      }));

      // Listen for the lobby ID and game code once sent back from the server
      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'lobby_created') {
          const newGameCode = message.gameCode;
        //console.log('Lobby created with game code:', newGameCode);
          navigate(`/lobby/${newGameCode}`); 
        }
      };
    } else {
    //console.log('WebSocket connection not available - create lobby');
      setErrorMessage("Connection error!");
    }
  };

  //join existing lobby
  const JoinGame = (code: string) => {

    if (validGameCode(code)) {
      if (socket && socket.readyState === WebSocket.OPEN)
      {
        console.log("checkingLobby")
        socket.send(JSON.stringify({
          type: 'check_lobby',
          gameCode: code,
          playerId: GetOrSetPlayerId()
        }));
  
      }
      else {
      //console.log('WebSocket connection not available');
        setErrorMessage("Error connecting to server")
        
      }

      // navigate(`/game/${gameCode}`);
    } else {
      setErrorMessage("Please enter the six-digit game code to join an existing game lobby.")
      // setHideRedPrompt(false);
    }
  };

  const onPreviousGameClick = () => {
    if (savedGameCode)
    {
      JoinGame(savedGameCode)
    }
    else
    {
      setErrorMessage("No previous game found")
    }
  }

  const handleGameCodeChange = (code: string) => {
    setGameCode(code);
    // console.log('Game code updated:', code);
  };

  const validGameCode = (code: string) => {
    const regex = /^[0-9]{6}$/;
    return regex.test(code);
  }

  return (
    <div className="landingPage">
      <Header></Header>
      <div className="section createGame" data-tooltip="Create a new game lobby and get a unique game code to share.">
        <PrettyButton onClick={handleCreateLobby} text="Create New Game" />
      </div>
      <hr className="separator" />
      <div className="section joinGame" data-tooltip="Enter the six-digit game code to join an existing game lobby.">
        <PrettyButton text="Join Game" onClick={() => JoinGame(gameCode)} buttonColor="#007FFF" />
        <br />
        <br />
        <NumpadInput onGameCodeChanged={handleGameCodeChange} />
      </div>
      <hr className="separator" />
      <div className="section previousGame" data-tooltip="Join previous entered lobby/game.">
        <PrettyButton text="Previous Game" onClick={onPreviousGameClick} buttonColor="#a72bea" />
        {/* <button className='test'>Previous Game</button> */}
        <br />
        <br />
        <br />

        <div className={`message`}>{errorMessage}</div>
      </div>

    </div>
  );
};

export default LandingPage;
