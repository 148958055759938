import React, { useEffect, useRef, useState } from 'react';
import { useWebSocket } from '../context/WebSocketContext';
import { Chat, Player, Team } from '../types';
import './PrettyChatbox.css';
import PrettyButton from './PrettyButton';
import { playNewMessageSound } from '../utilities/misc';

interface PrettyChatboxProps {
  gameCode: string;
  playerId: string;
  players: Player[];
  initialChat: Chat;
}

const PrettyChatbox: React.FC<PrettyChatboxProps> = ({ gameCode, playerId, players, initialChat }) => {
  const { socket } = useWebSocket();
  const [chat, setChat] = useState<Chat>(initialChat);
  const [localPlayers, setLocalPlayers] = useState<Player[]>(players);
  const [input, setInput] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false); // Toggle state for visibility
  const [lastOpenMessageCount, setLastOpenMessageCount] = useState(0);
  const [chatPlaceholder, setChatPlaceholder] = useState<string>(); // State to store the random string
  const messagesEndRef = useRef<HTMLDivElement>(null);
  // const messageCountLocalStorage = 'ssds-lastOpenMessageCount-LocalStorage'

  // Hardcoded array of strings
  const sillyMessages = [
  "Say something craaazyyy...",
  "Say heyyyyyy...",
  "Say wazzzzzuppp...",
  "Say yerrrrrr......"
  ];

  useEffect(() => {
    const registered = registeredPlayer();

    if (registered)
    {
      const randomIndex = Math.floor(Math.random() * sillyMessages.length);
      setChatPlaceholder(sillyMessages[randomIndex]);
    }
  }, [localPlayers])

  useEffect(() => {
    if (!socket) return;

    const handleIncomingMessage = (event: MessageEvent) => {
      const message = JSON.parse(event.data);
      if (message.type === "chat_updated") {
        playNewMessageSound();
        console.log(message.chat)
        setChat(message.chat);
      }

    };

    socket.onmessage = handleIncomingMessage;

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);
  

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // if (!registeredPlayer())
    // {
    //   setChatPlaceholder("Please pick a role & team before using the chat");
    //   return;
    // }

    // Choose a random string from the array
    const randomIndex = Math.floor(Math.random() * sillyMessages.length);
    setChatPlaceholder(sillyMessages[randomIndex]);
  }, [chat.messages]);

  useEffect(() => {
    if (isVisible)
    {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
      }
      
      setLastOpenMessageCount(chat.messages.length)
      // setChatNumberLocalStorage(chat.messages.length)
    }

    

  }, [isVisible, chat] )

  const sendMessage = () => {

    if (socket && socket.readyState === WebSocket.OPEN && input.trim().length > 0) {
      socket.send(
        JSON.stringify({
          type: 'update_chat',
          gameCode,
          playerId,
          chatMessage: input,
          isGuest: !registeredPlayer()
        })
      );
      setInput(''); // Clear input after sending
    }
  };

  const registeredPlayer = () => {
    return players.find(f => f.id === playerId)?.name ? true : false;
  }

  // Toggle visibility of the chatbox
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  }

  const missedMessages = () => {
    if (chat.messages.length < lastOpenMessageCount)
    {
      return 0;
    }

    return chat.messages.length - lastOpenMessageCount;
  }


  function checkNewMessages(): boolean {
    return chat.messages.length === lastOpenMessageCount || 
            chat.messages.length === 0
  }

  return (
    <div>
      {/* Toggle Button */}
      <div className={`toggle-chatbox ${!isVisible && missedMessages() > 0 ? "new" : ""}`} onClick={toggleVisibility}>
        {isVisible ? '💬 Hide Chat' : `💬 Show Chat${checkNewMessages() ? "": ` (${missedMessages()} new message${missedMessages() === 1 ? "" : "s"})`}`} {/* Arrow changes based on visibility */}
      </div>

      {/* Conditionally Render Chatbox */}
      {isVisible && (
        <div className="chatbox">
          <div className="chatBoxMessages">
            {chat.messages.map((msg, index) => {
              const time = new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
              const classAddition = `${msg.player.team} ${msg.player.role}`;
              const shortRole = msg.player.role === "spymaster" ? " 🙈" : msg.player.role === "guesser" ? " 👀" : ""
              const playerName = msg.player.name;

              return (
                <div key={index} className={`chatBoxMessage ${classAddition}`}>
                  <div>
                    <strong>[{`${playerName}${shortRole}`}]</strong> ({time}): {msg.text}
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} /> {/* Marker to scroll to */}
          </div>
          <input
            type="text"
            value={input}
            // disabled={!registeredPlayer()}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                sendMessage();
              }
            }}
            placeholder={chatPlaceholder}
          />
          <div className='sendButtonContainer'>
            <PrettyButton onClick={sendMessage} text="Send" buttonColor='#a72bea' />

          </div>
        </div>
      )}
    </div>
  );
};

export default PrettyChatbox;
