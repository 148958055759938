import React from 'react';
import './ClueNumberDropdown.css';

interface NumberDropdownProps {
  onValueChange: (value: number) => void; // Explicitly define the type of the prop
  value: number;
  disabled: boolean;
  specialChange: boolean;
}

const NumberDropdown: React.FC<NumberDropdownProps> = ({ onValueChange,value,disabled, specialChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value, 10); // Parse the string value to a number
    onValueChange(value); // Call parent's handler with the number value
  };

  return (
    <select onChange={handleChange} className={`pretty-dropdown flashing ${disabled ? "disabled": ""}`} value={value} disabled={disabled}>
      <option value={undefined} disabled hidden></option> {/* Default empty option */}
      {[...Array(9)].map((_, i) => (
        <option key={i + 1} value={i + 1}>
          {i + 1}
        </option>
      ))}
    </select>
  );
};

export default NumberDropdown;
