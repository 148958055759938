import { Howl } from 'howler'

const lastGameCodeKey = 'SSDS-LastGameCodeKey'

const buttonClickSound = new Howl({
  src: ['/audio/big_click.wav'],
  volume: 0.25
})

const tileClickSound = new Howl({
  src: ['/audio/tile_click.wav'],
  volume: 0.25 
})

const neutralTileClickSound = new Howl({
  src: ['/audio/neutral_reveal.wav'],
  volume: 0.25 
})

const goodTileClickSound = new Howl({
  src: ['/audio/good_reveal1.wav'],
  // src: ['/audio/good_reveal2.wav'],
  volume: 0.25 
})

const badTileClickSound = new Howl({
  src: ['/audio/bad_reveal.wav'],
  volume: 0.25 
})

const explosionSound = new Howl({
  src: ['/audio/explosion.wav']
})

const newMessageSound = new Howl({
  src: ['audio/new_message.wav'],
  volume: 0.25
  
})

const winningMusic = new Howl({
  src: ['audio/winner.wav']
})

const losingMusic = new Howl({
  src: ['audio/loser.wav']
})

export const playButtonClickSound = () => {
  buttonClickSound.play();
}

export const playTileClickSound = () => {
  tileClickSound.play();
}

export const playGoodTileClickSound = () => {
  goodTileClickSound.play();
}

export const playBadTileClickSound = () => {
  badTileClickSound.play();
}

export const playNeutralTileClickSound = () => {
  neutralTileClickSound.play();
}

export const playExplosionSound = () => {
  explosionSound.play();
}

export const playNewMessageSound = () => {
  newMessageSound.play();
}

export const playWinningMusic = () => {
  winningMusic.play();
}

export const playLosingMusic = () => {
  losingMusic.play();
}

// Utility to get or set a cookie for player ID
export const GetOrSetPlayerId = (): string => {
  const existingId = document.cookie.split('; ').find(row => row.startsWith('playerId='));
  if (existingId) {
    return existingId.split('=')[1];
  } else {
    const newId = `player-${Math.random().toString(36).substring(2, 9)}`;
    document.cookie = `playerId=${newId}; path=/; max-age=31536000`; // Set cookie for 1 year
    return newId;
  }
};

export const saveLastGameCode = (gameCode: string) => {
  localStorage.setItem(lastGameCodeKey, gameCode)
}

export const retrieveLastGameCode = (): string | undefined => {
  const resultString = localStorage.getItem(lastGameCodeKey);

  return resultString || undefined;
}

