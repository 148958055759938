import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import GameBoard from './pages/GameBoard';
import Lobby from './pages/Lobby';
import Header from './components/Header'; // Import the Header component
import { useWebSocket } from './context/WebSocketContext';
import './App.css';

const App: React.FC = () => {
  const { socket } = useWebSocket();
  const [isSocketReady, setIsSocketReady] = useState(false);

  // Track WebSocket state
  useEffect(() => {
    if (socket) {
      setIsSocketReady(true); // Set WebSocket as ready when it's connected
    } else {
      console.log('Waiting for WebSocket connection...');
    }
  }, [socket]);

  return (
    <Router>
      <Routes>
        {/* Only show the content when WebSocket is ready */}
        {isSocketReady ? (
          <>
            {/* Define your main routes */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/game/:gameCode" element={<GameBoard />} />
            <Route path="/lobby/:gameCode" element={<Lobby />} />

            {/* Handle undefined routes (wildcard) */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          // Loading state with Header shown
          <Route
            path="*"
            element={
              <>
                <Header /> {/* Show Header during WebSocket connection */}
                {/* <div className="loading-screen"> */}
                  {/* <h1>Connecting to server...</h1> */}
                {/* </div> */}
              </>
            }
          />
        )}
      </Routes>
    </Router>
  );
};

export default App;
