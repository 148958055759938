import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface WebSocketContextType {
  socket: WebSocket | null;
}

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [retryAttempt, setRetryAttempt] = useState(0);

  const isLocalHost = window.location.hostname === 'localhost';

  const wsUrl = isLocalHost
    ? 'ws://localhost:8080' 
    : 'wss://server.samesportdifferentstadium.com/';

  const connectWebSocket = () => {
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      setRetryAttempt(0); // Reset retry attempts on successful connection
      console.log('Connected to WebSocket server');
      setSocket(ws); // Set socket on successful connection
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // if (isLocalHost) {
        console.log('Received message:', message);
      // }
    };

    const handleReconnect = () => {
      console.log('Attempting to reconnect...');
      const retryDelay = Math.min(1000 * 2 ** retryAttempt, 10000); // Exponential backoff with max delay of 30 seconds
      setTimeout(() => {
        setRetryAttempt((prev) => prev + 1);
        connectWebSocket(); // Re-attempt connection
      }, retryDelay);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      handleReconnect();
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      handleReconnect();
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket && socket.readyState !== WebSocket.CLOSED) {
        socket.close(); // Ensure socket is closed on unmount
      }
    };
  }, []); // Only run on mount and unmount

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to access the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
