import React, { useState, useEffect, useRef } from 'react';
import './NumpadPrompt.css';

interface NumpadInputProps {
  onGameCodeChanged: (code: string) => void;
}

const NumpadInput: React.FC<NumpadInputProps> = ({ onGameCodeChanged }) => {
  const [gameCode, setGameCode] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  //autofocus
  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, []);

  const handleGameCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Replace non-numeric characters with an empty string
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    setGameCode(numericValue);
    onGameCodeChanged(numericValue);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <input
          type="tel"
          maxLength={6}
          value={gameCode}
          onChange={handleGameCodeChange}
          placeholder="Six-digit game code:"
          className="game-code-input"
          ref={inputRef}
          pattern="[0-9]*"
          inputMode="numeric"
          required
        />
        <br />
      </div>
    </div>
  );
};

export default NumpadInput;
