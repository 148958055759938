import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWebSocket } from '../context/WebSocketContext';
import './Header.css';

const Header = () => {
  const connectedSuccessString = "✓ Connected"
  const [connectionStatus, setConnectionStatus] = useState<string | undefined>("Connecting...");
  const [goodConnection, setGoodConnection] = useState(false);
  const { socket } = useWebSocket();


  useEffect(() => {
    console.log("checking status in header")
    const updateConnectionStatus = () => {
      if (socket) {
        switch (socket.readyState) {
          case WebSocket.CONNECTING:
            setConnectionStatus("⏳ Connecting...");
            break;
          case WebSocket.OPEN:
            setConnectionStatus(connectedSuccessString);
            break;
          case WebSocket.CLOSING:
            setConnectionStatus("🔁 Disconnecting...");
            break;
          case WebSocket.CLOSED:
            setConnectionStatus("✗ Disconnected. Click here to reconnect...");
            break;
          default:
            setConnectionStatus("Unknown Status");
        }
      }
    };

    // Update connection status when socket changes state
    updateConnectionStatus(); // Run initially to set the initial status
    const interval = setInterval(updateConnectionStatus, 500); // Check every 500ms for changes

    // const handleMessage = (event: MessageEvent) => {
    //   //console.log('Message received:', event.data); 
    //     const message = JSON.parse(event.data);
    //     if (message.type === "error"){
    //       setConnectionStatus(message.message);
    //     }
    //   };

    // // Register the listener
    // if (socket)
    // {
    //   socket.addEventListener('message', handleMessage);
    // }

    // Cleanup
       
    return () => {
      clearInterval(interval);
      // if (socket)
      // {
      //   socket.removeEventListener('message', handleMessage); // Clean up listener on unmount
      // }
     };
  }, [socket]);

  const navigate = useNavigate();

  const goHome = () => {
    navigate(`/`);
  };

  const refreshPage = () => {
    if (!goodConnection) {
      window.location.reload(); // Refreshes the page
    }
  };

  useEffect(() => {
    switch (connectionStatus)
    {
      case connectedSuccessString:
        setGoodConnection(true);
        break;
      default:
        setGoodConnection(false);
        break;
    }

  }, [connectionStatus]);

  return (
    <div>
      <img 
        src={`${process.env.PUBLIC_URL}/ssds-banner.png`} 
        alt="Same Sport, Different Stadium" 
        onClick={goHome} 
        className="headerImage" 
      />
      <div className={`connectionMessage ${!goodConnection ? "error" : ""}`} onClick={refreshPage}>{connectionStatus}</div>
      <hr className="separator" />

    </div>
  );
};

export default Header;
